<template>
  <div class="home-page__box-img-title">
    <swiper ref='imageSwiper' :options='imageSwiperOptions'>
      <div v-for='(item, index) in slides' :key='index' class='swiper-slide'>
        <img :src='item.image' :alt='item.text'>
      </div>
    </swiper>
    <div class="home-page__title-box">
      <div class="home-page__title-sm">Choose your competition</div>
      <swiper ref='textSwiper' :options='textSwiperOptions'>
        <swiper-slide v-for='(item, index) in slides' :key='index'>
          <span @click='changeSlide(index)' class='home-page__type' :class='{"home-page__type_active": active === index}'>{{item.text}}</span>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SportSelect',
  data() {
    const component = this
    return {
      textSwiperOptions: {
        initialSlide: 0,
        slidesPerView: 1,
        on: {
          activeIndexChange() {
            if (component.$refs.imageSwiper.$swiper) {
              component.$refs.imageSwiper.$swiper.slideTo(this.activeIndex)
            }
            component.setSport(this.activeIndex)
          }
        }
      },
      imageSwiperOptions: {
        initialSlide: 0,
        on: {
          activeIndexChange() {
            if (component.$refs.textSwiper.$swiper) {
              component.$refs.textSwiper.$swiper.slideTo(this.activeIndex)
            }
            component.setSport(this.activeIndex)
          }
        }
      }
    }
  },
  created() {
    this.textSwiperOptions.initialSlide = this.active
    this.imageSwiperOptions.initialSlide = this.active
  },
  computed: {
    ...mapState({
      slides: state => state.home.sportView.list,
      active: state => state.home.sportView.selected,
    })
  },
  methods: {
    changeSlide(index) {
      if (this.$refs.textSwiper.$swiper) {
        this.$refs.textSwiper.$swiper.slideTo(index)
      }
    },
    ...mapMutations({
      setSport: 'home/SET_SELECTED_SPORT'
    })
  }
}
</script>

<style lang="stylus">
.home-page__box-img-title {
  margin -62px -16px 15px
  border-radius: 0 0 12px 12px;
  overflow hidden
  position relative
}

.home-page__box-img-title img {
  width 100%
  display block
}

.home-page__title-box {
  font-family: 'Poppins', sans-serif;
  z-index: 100
  font-size: 36px;
  line-height 40px
  color #fff
  margin-bottom 12px
  font-weight: bold;
  position absolute
  bottom: 0
  right: 0
  left: 0
  .swiper-container {
    width:80%
    margin: 0
    overflow visible
    max-width 400px
  }
}

.home-page__title-sm {
  letter-spacing: 0.005em;
  color: #F2F2F2;
  margin-bottom 8px
  transform translateX(16px)
  font-size:1rem
  font-weight:normal
  margin: 0
  user-select: none;
  pointer-events: none;
  position absolute
  bottom:100%
}
.home-page__type {
  padding: 0 16px
  opacity 0.4
  cursor pointer
}
.home-page__type_active {
  opacity 1
}
</style>
