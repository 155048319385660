<template>
  <view-container name="competitions">
    <view-header :fixed="true">
      <header-competitions>My Competitions</header-competitions>
    </view-header>

    <view-content>
      <SportSelect />
      <div class="content">
        <div v-if="selectedSport.key === 'swimming'" type="card">
          <div class="competitions" key="pending">
            <div v-for="item in sortItems" :key="`${item.entity}_${item.uid}`">
              <component :is="itemComponent(item.entity)" :tournament="item" :competition="item" />
            </div>


            <!-- <Tournament v-for="tournament in tournaments" :key="`tr_${tournament.id}`" :tournament="tournament" />

            <Competition v-for="competition in allSwimmingCompetitions" :key="`cm_${competition.id}`"
              :competition="competition" /> -->
          </div>

          <!-- <a-tab-pane key="invites">
            <div slot="tab" class="title" ref="tabInvites">
              <span>{{ $t('pages.competitions.invites') }}</span>

              <a-badge v-if="hasActiveCompetition" color="#4285F4" />
            </div>

            <Invites v-for="opponent in swimmingInvites" :key="`op_${opponent.id}`" :data-opponent="opponent"
              :competition="getCompetitionProm(opponent.competition.id)" @hasActive="hasActiveCompetition = true" />
            <Ad />
          </a-tab-pane> -->
        </div>

        <div v-if="selectedSport.key === 'basketball'" type="card" @tabClick="tabClick">
          <div class="competitions" key="pending">
            <Competition v-for="competition in basketballCompetitions" :key="`cm2_${competition.id}`"
              :competition="competition" @has-active="hasActiveCompetition = true" />
          </div>
        </div>

        <p v-show="selectedSport.key === 'workout'" class="coming-soon">Coming soon</p>

        <FloatButton @click="goToNewCompetition" />

        <NewCompetition :open="popup" @close="togglePopup" />
      </div>
    </view-content>

    <view-footer>
      <footer-competitions />
    </view-footer>
  </view-container>
</template>

<script>
// import Invites from '@/components/Competitions/Invites'
// import { getEvents, getEventsVerdicts } from '@/api/public/competition'
import SportSelect from '@/components/SportSelect'
import Competition from '@/components/Competitions/Competition'
import Tournament from '@/components/Competitions/Tournament'
import FloatButton from '@/components/FloatButton'
import NewCompetition from '@/components/Popup/NewCompetition'
import {
  mapState,
  mapGetters
} from 'vuex'

export default {
  name: 'Competitions',
  components: {
    SportSelect,
    Competition,
    Tournament,
    FloatButton,
    NewCompetition,
  },
  // async created() {
  //   await this.getAllEvents()
  // },
  mounted() {
    console.log("🚀 ~ file: Competitions.vue ~ line 119 ~ mounted ~ sortItems", this.sortItems)
    this.$store.dispatch('competitions/init')
  },
  data() {
    return {
      popup: false,
      tabKey: 'pending',
      competitionsByInvites: [],
      hasActiveCompetition: false,
      userId: this.$store?.state?.auth?.user?.id,
    }
  },
  computed: {
    ...mapGetters(['profileFilled']),
    ...mapGetters({
      existUnseenInvites: 'competitions/existUnseenInvites',
      selectedSport: 'home/selectedSport'
    }),
    ...mapState('competitions', {
      competitions: ({
        competitions
      }) => competitions,
      tournaments: ({
        tournaments
      }) => tournaments,
      events: ({
        events
      }) => events,
      invites: ({
        invites
      }) => invites
    }),
    activeTabName() {
      switch (this.tabKey) {
        case 'pending':
          return this.$t('pages.competitions.pending');
        case 'invites':
          return this.$t('pages.competitions.invites');
        default:
          return ''
      }
    },
    filterEvents() {
      return this.events.filter(el => !this.IsLive(el))
    },
    swimmingCompetitions() {
      return this.competitions.filter(el => (el.kind_sport.name === "Swimming"));
    },
    allSwimmingCompetitions() {
      return [...this.filterEvents, ...this.swimmingCompetitions]
    },
    basketballCompetitions() {
      return this.competitions.filter(el => (el.kind_sport.name === "Basketball"));
    },

    // basketballInvites() {
    //   return this.invites.filter(el => (el.competition.kind_sport.name === "Basketball")).sort((a, b) => {
    //     if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
    //     if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
    //     return 0
    //   });
    // },
    // // activeBasketballInvites() {
    // //   return this.basketballInvites.filter(el => (el.status === 'Waiting')).length > 0
    // // },
    sortItems() {
      const data = [...this.tournaments, ...this.allSwimmingCompetitions]
      // console.log('TEST', data);
      return data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    }
  },
  methods: {
    goToNewCompetition() {
      this.profileFilled ?
        this.$router.push({
          name: 'newcompetition'
        }) :
        this.togglePopup()
    },
    togglePopup() {
      this.popup = !this.popup
    },
    tabClick(value) {
      this.tabKey = value
      const routeWithHash = `${this.$route.path}#${value}`
      if (this.$route.fullPath !== routeWithHash) {
        this.$router.push(routeWithHash)
      }
      if (value === 'invites') {
        this.$store.dispatch('competitions/addAllToViewedInvites');
      }
    },
    itemComponent(type) {
      let component = 'competition'
      switch (type) {
        case 'tournament':
          component = 'tournament'
          break;
        default:
      }
      return component
    },

    IsLive(event) {
      if (event.status === "video-is-live") {
        if (event.competitions && event.competitions.find(el => el.status !== "video-is-live")) {
          return false
        }
        return true
      }
      return false
    }
  },
  async swipeUpdate() {
    await this.allIsLive()
    await this.$store.dispatch('competitions/init')
    // await this.$store.dispatch('competitions/getCompetitions')
    // await this.$store.dispatch('competitions/getTournaments')
    // await this.$store.dispatch('competitions/getEvents')
    // await this.$store.dispatch('home/getEventsVerdicts')
    // await this.$store.dispatch('home/getEvents')
    //await this.$store.dispatch('competitions/getInvites')
    // await this.getTournVerdicts()
  }
}
</script>